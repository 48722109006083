import TYPES from '@/types';
import { currencyFormat } from '@/vue-app/utils/currency';

// Application
import GetPersonQueryService
  from '@/modules/my-investment/person/application/queries/get-my-investment-person-query';
import { SearchOnBoardingStepsQuery }
  from '@/modules/my-investment/on-boarding-steps/application/queries';
import { SearchInvestmentProvidersQuery }
  from '@/modules/my-investment/investment-provider/application/queries';
import {
  OnBoardingStepEntity,
} from '@/modules/my-investment/on-boarding-steps/domain/entities/on-boarding-step-entity';
import {
  InvestmentProviderEntity,
} from '@/modules/my-investment/investment-provider/domain/entities/investment-provider-entity';

// Domain
import Inject from '@/modules/shared/domain/di/inject';
import { MessageNotifier } from '@/modules/shared/domain/notifiers/message_notifier';

export default class ContractSavingsCollectionFinishCollectionViewModel {
  @Inject(TYPES.GET_INVESTMENT_PERSON_QUERY)
  private readonly get_person_query!: GetPersonQueryService;

  @Inject(TYPES.SEARCH_MY_INVESTMENT_ON_BOARDING_STEP_REPOSITORY)
  private readonly search_on_boarding_steps_query!: SearchOnBoardingStepsQuery;

  @Inject(TYPES.SEARCH_INVESTMENT_PROVIDER_QUERY)
  private readonly search_investment_providers_query!: SearchInvestmentProvidersQuery;

  @Inject(TYPES.NOTIFIER)
  readonly message_notifier!: MessageNotifier;

  private readonly investment_provider_name = 'allianz';

  readonly i18n_namespace = 'components.contract-savings.collection.finish-collection';

  investment_provider_id = '';

  is_loading = true;

  small_screen: null | boolean = null;

  internal_step = 1;

  data = {
    name: '',
    clabe: '',
    concept: 'Aportación inicial + Prima única',
    amount: '',
  };

  monthly_collection_day = 1;

  recurring_contribution_amount = '0';

  get person() {
    return this.get_person_query.execute();
  }

  get investmentProviders() {
    return this.search_investment_providers_query.execute();
  }

  get steps() {
    return this.search_on_boarding_steps_query.execute(this.investment_provider_id);
  }

  setInvestmentProviderId = (investment_providers: Array<InvestmentProviderEntity>) => {
    if (investment_providers.length) {
      const search_provider = investment_providers.find(
        (item) => item.name === this.investment_provider_name,
      );
      if (search_provider) {
        this.investment_provider_id = search_provider.id;
      }
    }
  };

  searchStep = (step_name: string, on_boarding_steps: Array<OnBoardingStepEntity>) => {
    const search_step = on_boarding_steps.find(
      (step) => step.current_step === step_name,
    );
    if (search_step) {
      return search_step;
    }
    return null;
  };

  fillData = async () => {
    const steps = await this.steps;
    const account_information_step = this.searchStep('account_information', steps);
    const contributions_step = this.searchStep('contributions', steps);
    const recurring_contributions_step = this.searchStep('recurring_contributions', steps);
    // eslint-disable-next-line max-len
    const full_name = `${this.person.name} ${this.person.last_name} ${this.person.second_last_name}`;
    // eslint-disable-next-line max-len
    const total = parseFloat(contributions_step?.payload.initial_contribution_amount) + parseFloat(contributions_step?.payload.unique_premium);
    this.data = {
      name: full_name,
      clabe: `**** **** **** ${account_information_step?.payload.account_number.slice(-6)}`,
      concept: 'Aportación inicial + Prima única',
      amount: currencyFormat(total),
    };
    this.monthly_collection_day = recurring_contributions_step?.payload.monthly_collection_day;
    this.recurring_contribution_amount = recurring_contributions_step?.payload
      .recurring_contribution_amount;
  }

  initialize = async () => {
    const investment_providers = await this.investmentProviders;
    this.setInvestmentProviderId(investment_providers);
    await this.fillData();

    this.is_loading = false;
  }
}
