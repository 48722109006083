



















































































import { Vue, Component, Prop } from 'vue-property-decorator';
import { getScreenSizeVariant } from '@/vue-app/utils/screen';

@Component({ name: 'ContractSavingsCollectionReminder' })
export default class ContractSavingsCollectionReminder extends Vue {
  @Prop({ required: true, type: Boolean })
  readonly isOpen!: boolean;

  @Prop({ required: true, type: String })
  readonly amount!: string;

  @Prop({ required: true, type: Number })
  readonly day!: number;

  i18n_namespace = 'components.contract-savings.collection.reminder';

  small_screen: null | boolean = null;

  prevStep() {
    this.$emit('prevStep');
  }

  nextStep() {
    this.$emit('nextStep');
  }

  updateRecurringContributions() {
    this.$emit('updateRecurringContributions');
  }

  mounted() {
    this.small_screen = getScreenSizeVariant() === 'small';
  }
}

