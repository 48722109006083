var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"h-full"},[(_vm.finish_collection_view_model.internal_step !== 3
    || !_vm.finish_collection_view_model.small_screen)?_c('div',{staticClass:"h-full d-flex justify-space-between flex-column"},[_c('div',[_c('h2',{staticClass:"primary--text text-center mb-2 d-none d-md-block"},[_vm._v(" "+_vm._s(_vm.$t(((_vm.finish_collection_view_model.i18n_namespace) + ".title")))+" ")]),_c('p',{class:{
          'text-text mb-8 primary--text': true,
          'text-center': !_vm.finish_collection_view_model.small_screen,
        }},[_vm._v(" "+_vm._s(_vm.$t(((_vm.finish_collection_view_model.i18n_namespace) + ".description")))+" ")]),_c('h6',{staticClass:"primary---text mb-3 mb-md-7"},[_vm._v(" "+_vm._s(_vm.$t(((_vm.finish_collection_view_model.i18n_namespace) + ".resume")))+" ")]),_vm._l((Object.keys(_vm.finish_collection_view_model.data)),function(field){return _c('div',{key:field,staticClass:"px-md-5 py-4 py-md-0 mb-md-10"},[_c('v-row',{attrs:{"align":"center"}},[_c('v-col',{staticClass:"pb-0 pb-md-3",attrs:{"cols":"12","md":"6"}},[_c(_vm.finish_collection_view_model.small_screen ? 'label' : 'p',{tag:"component",class:{
                'dark-grey--text': true,
                'text-body': !_vm.finish_collection_view_model.small_screen,
              }},[_vm._v(" "+_vm._s(_vm.$t(((_vm.finish_collection_view_model.i18n_namespace) + "." + field)))+" ")])],1),_c('v-col',{staticClass:"pt-0 pt-md-3",attrs:{"cols":"12","md":"6"}},[_c('p',{staticClass:"primary--text text-body"},[_vm._v(" "+_vm._s(_vm.finish_collection_view_model.data[field])+" ")])])],1),_c('v-divider')],1)}),_c('small',{staticClass:"dark-grey--text px-md-5",staticStyle:{"float":"left"}},[_vm._v(" "+_vm._s(_vm.$t(((_vm.finish_collection_view_model.i18n_namespace) + ".transaction_lapse")))+" ")])],2),_c('v-row',{attrs:{"align":"end"}},[_c('v-col',{staticClass:"d-none d-md-block",attrs:{"md":"6"}},[_c('v-btn',{attrs:{"rounded":"","color":"secondary","large":"","width":"100%","height":"3.5em","disabled":_vm.finish_collection_view_model.is_loading},on:{"click":_vm.updateInitialContributions}},[_vm._v(" "+_vm._s(_vm.$t(((_vm.finish_collection_view_model.i18n_namespace) + ".edit")))+" ")])],1),_c('v-col',{staticClass:"text-center",attrs:{"md":"6","cols":"12"}},[_c('v-btn',{attrs:{"rounded":"","color":"accent","large":"","width":_vm.finish_collection_view_model.small_screen ? '80%' : '100%',"height":"3.5em","disabled":_vm.finish_collection_view_model.is_loading},on:{"click":_vm.nextStep}},[_vm._v(" "+_vm._s(_vm.$t(((_vm.finish_collection_view_model.i18n_namespace) + ".continue")))+" ")])],1)],1)],1):_vm._e(),(!!_vm.finish_collection_view_model.recurring_contribution_amount)?_c('contract-savings-collection-reminder',{attrs:{"isOpen":_vm.finish_collection_view_model.internal_step === 2,"amount":_vm.finish_collection_view_model.recurring_contribution_amount,"day":_vm.finish_collection_view_model.monthly_collection_day},on:{"nextStep":_vm.nextStep,"updateRecurringContributions":_vm.updateRecurringContributions}}):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }